import React from 'react'

function Avviso() {
	return (
		<>
			{new Date() < new Date('2024/08/26') && (
				<div className="avviso">
					<div className="titolo_avviso">
						<b>Pausa estiva</b>
						{/* <b>Pausa Natalizia</b> */}
						{/* <b>Il catalogo edizione 2022 sta per terminare.</b> */}
					</div>
					<div className="testo_avviso">
						Le spedizioni dei premi saranno interrotte dal <b>07/08/2024</b> al <b>25/08/2024</b> inclusi e riprenderanno regolarmente a partire dal{' '}
						<b>26/08/2024</b>.
						<br />
						Tutti i prodotti ordinati dal <b>07/08/2024</b> saranno quindi regolarmente spediti dal <b>26/08/2024</b>.
					</div>
				</div>
			)}
		</>
	)
}

export default Avviso
